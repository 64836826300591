<template>
  <b-card-code>
    <b-row>
      <b-col sm="6">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          :disabled="loading"
          :to="'/portal/admin/roles-add'"
        >
          Agregar Rol
        </b-button>
      </b-col>
      <b-col sm="6">
        <!-- search input -->
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <b-form-input
              v-model="searchTerm"
              placeholder="Buscar"
              type="text"
              class="d-inline-block"
              @keyup.enter="search(rows, searchTerm)"
            />
          </b-form-group>
          <b-form-group>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              :disabled="loading || searching"
              class="ml-1"
              @click="search(rows, searchTerm)"
            >
              <span v-if="!searching">Buscar</span>
              <span v-if="searching">Buscando...</span>
            </b-button>
          </b-form-group>
        </div>
      </b-col>

    </b-row>
    <b-row>
      <b-col>
        <p>Mostrando: {{ rowsFiltered.length }} de {{ rows.length }}</p>
      </b-col>
    </b-row>
    <!-- table -->
    <vue-good-table
      :columns="columns"
      :is-loading="loading"
      :rows="dataRoles"
      :rtl="direction"
      :fixed-header="true"
      max-height="500px"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :sort-options="{
        enabled: true,
        initialSortBy: {field: 'status.status', type: 'asc'}
      }"
    >
      <div
        slot="emptystate"
        class="text-center"
      >
        No se encontraron datos
      </div>
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Status -->
        <span v-if="props.column.field === 'status.status'">
          <b-badge>
            {{ props.row.status.status }}
          </b-badge>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="ListIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                @click="$router.push(
                  { name:'roles-edit',
                    params:{
                      id: props.row.GRUPO
                    }
                  })"
              >
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>Editar Rol</span>
              </b-dropdown-item>
              <b-dropdown-item
                @click="$router.push(
                  { name:'roles-permissions',
                    params:{
                      id: props.row.GRUPO
                    }
                  })"
              >
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>Editar Permisos</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>


        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Mostrando 1 de
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['10','25', '50', '100']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> de {{ props.total }} registros </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card-code>
</template>

<script>
import {
  BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BRow, BCol,
  BButton, BDropdown, BDropdownItem
} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import { downloadExcel } from '@core/utils/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'
import environment from '@/environment'

export default {
  name: 'AuxiliaryApproval',
  components: {
    VueGoodTable,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BButton,
    BDropdown,
    BDropdownItem,
    BCardCode,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: true,
      searching: false,
      pageLength: 100,
      dir: false,
      dataRoles: [],
      columns: [
        {
          label: 'Roles',
          field: 'ROL',
        },
        {
          label: 'Grupo AD',
          field: 'GRUPO',
        },
        {
          label: 'Descripción',
          field: 'DESCRIPCION',
          sortable: false,
        },
        {
          label: 'Estado',
          field: 'status.status',
        },
        {
          label: '',
          field: 'action',
          width: '100px',
          sortable: false,
        },
      ],
      rows: [],
      rowsFiltered: [],
      statuses: [
        { idStatus: 'P', status: 'Preactivo' },
        { idStatus: 'C', status: 'Cerrado' },
        { idStatus: 'X', status: 'Inhabilitada' },
        { idStatus: 'A', status: 'Activo' },
      ],
      searchTerm: '',
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.load()
  },
  methods: {
    async load() {
      this.loading = true
      try {
        const resp = await this.$http.get(`${environment.uri}/users/roles`)

        if (resp && resp.data) {
          this.dataRoles = resp.data.map(item => {
            const status = this.statuses.find(s => s.idStatus === item.STATUS) || { idStatus: 'X', status: 'Sin Estado' }
            return {
              ...item,
              status,
            }
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error en la carga',
              icon: 'BellIcon',
            },
          })
        }
      } catch (err) {
        console.error(err)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error en la carga',
            icon: 'BellIcon',
          },
        })
      } finally {
        this.loading = false
      }
    },
    async update(unidad) {
      this.loading = true
      try {
        const user = JSON.parse(localStorage.getItem('userData'))
        await this.$http.put(`${environment.uri}/workflows/approval/id/${user.idCentro}`, {
          unidad,
          usuario: user.email,
        })
        await this.load()
      } catch (err) {
        console.error(err)
      } finally {
        this.loading = false
      }
    },
    async upload(unit, period) {
      this.loading = true
      try {
        await this.$http.get(`${environment.uri}/workflows/import/unit/${unit}/period/${period}`)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Cargado',
            icon: 'BellIcon',
          },
        })

        await this.load()

      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error en la carga',
            icon: 'BellIcon',
          },
        })
        console.error(err)
      } finally {
        this.loading = false
      }
    },
    currency(value) {
      return `$${this.$options.filters.currency(value)}`
    },
    date(value) {
      return this.$options.filters.date(value)
    },
    download(rows) {
      const items = rows.map(item => ({
        periodo: (item.periodo || ''),
        idCentro: (item.idCentro || ''),
        unidad: (item.unidad || ''),
        descripcion: (item.descripcion || ''),
        status: (item.status.status || ''),
        creado: (item.creado || 0),
        aprobado: (item.aprobado || 0),
      }))
      downloadExcel(items, [
        'Periodo',
        'Id Centro',
        'Unidad',
        'Descripción',
        'Estado',
        'Creado',
        'Aprobado',
      ], 'report.csv')
    },
    search(arr, searchKey) {
      this.searching = true
      if (searchKey) {
        this.rowsFiltered = arr.filter(obj => Object.keys(obj).some(key => String(obj[key]).toLowerCase().includes(searchKey.toLowerCase())))
      } else {
        this.rowsFiltered = this.rows.slice(0, 100)
      }
      this.searching = false
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
